import React from "react";
import PortableText from "./portableText";
import PropTypes from "prop-types";

function ContentColumn({ anchor, title, subtitle, clientConfig, columnsBool, content }) {
  return (
    <div id={anchor}>
      <div className="container mx:auto">
        {title && <h2 className="m-0">{title}</h2>}
        {subtitle && (
          <strong className="content-subtitle">
            <PortableText blocks={subtitle} clientConfig={clientConfig} />
          </strong>
        )}
      </div>
      <div className="container mx:auto flex content-column">
        <div
          className={columnsBool ? "pr-6 adjustable-columns" : "w-full flex flex-wrap mb-4 pr-6"}
        >
          <PortableText blocks={content} clientConfig={clientConfig} />
        </div>
      </div>
    </div>
  );
}

ContentColumn.propTypes = {
  subtitle: PropTypes.array,
  firstColumn: PropTypes.array,
  secondColumn: PropTypes.array,
};

export default ContentColumn;
