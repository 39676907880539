import React from "react";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import PortableText from "./portableText";

export default function AccordionContent(props) {
  return (
    <div>
      <div>
        <h2 className="mb-1">{props.accordionTitle}</h2>
      </div>
      <Accordion allowZeroExpanded>
        {props.accordionRows &&
          props.accordionRows.map((row, index) => {
            return (
              <AccordionItem key={index}>
                <AccordionItemHeading>
                  <AccordionItemButton>{row.title}</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div style={{ width: "auto", maxWidth: "992px" }}>
                    <PortableText blocks={row.content} clientConfig={props.clientConfig} />
                  </div>
                </AccordionItemPanel>
              </AccordionItem>
            );
          })}
      </Accordion>
    </div>
  );
}
