import React from "react";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import TimelineEntry from "./timeline-entry";
import "react-vertical-timeline-component/style.min.css";
import { pickTextColorBasedOnBgColor, percentToHex } from "../lib/helpers";
import { GoCalendar } from "react-icons/go";

export default (props) => {
  return (
    <VerticalTimeline layout="1-column-left" className="timeline-line-colour">
      <h2>{props.title}</h2>
      {props.entries.map((entry) => {
        // If no colour has been defined, fall back to a default
        if (entry.entryColour == null) {
          entry.entryColour = { hex: "#FFFFFF", alpha: 1 };
        }
        const coloursObject = entry.entryColour;
        const colour = coloursObject.hex + percentToHex(coloursObject.alpha * 100);
        // Render
        return (
          <VerticalTimelineElement
            visible={true}
            key={entry._key}
            className="vertical-timeline-element--work"
            // Set background to hex + alpha value, set color to either black or white, whichever shows up better against that colour
            contentStyle={{
              background: colour,
              color: pickTextColorBasedOnBgColor(colour),
            }}
            contentArrowStyle={{ borderRight: "7px solid " + colour }}
            date={entry.dateString}
            iconStyle={{
              // It looks ugly if the icon has a transparent background as you can see the timeline line behind the icon.
              // Not ideal, but for now, default to white if they're trying to set a transparent colour.
              background: coloursObject.alpha < 1 ? "#ffffff" : colour,
              color: pickTextColorBasedOnBgColor(coloursObject.alpha < 1 ? "#ffffff" : colour),
            }}
            icon={<GoCalendar />}
          >
            <TimelineEntry
              title={entry.title}
              subtitle={entry.subtitle}
              content={entry.content}
              link={entry.link ? entry.link[0] : null}
            />
          </VerticalTimelineElement>
        );
      })}
    </VerticalTimeline>
  );
};
