import React from "react";
import CTALink from "./CTALink";

const FloatingWidget = (props) => {
  return (
    <aside
      className="max-w-sm text-center bg-white p-4 mb-6 sm:mb-0 mx-2 sm:mx-0 rounded-md sm:right-px sm:bottom-px sticky top-0 sm:top-auto sm:fixed flex sm:flex-col items-center z-10"
      style={{ bottom: "1em", right: "1em", border: "2px solid #e40087" }}
    >
      <style
        dangerouslySetInnerHTML={{
          __html: `
      a.magenta { background-color: #e40087 }
    `,
        }}
      />
      <div className="flex flex-col mr-6 sm:mr-0 items-center">
        {props.title && <h4 className="mb-0 sm:mb-4">{props.title}</h4>}
        {props.text && <p className="hidden sm:block mb-0">{props.text}</p>}
      </div>
      {props.ctaButton && (
        <CTALink
          {...props.ctaButton}
          kind="button" // No you can't have a link instead of a button here
          buttonActionClass="ml-auto sm:mx-auto hover:underline text-white font-bold rounded-full sm:my-6 py-4 px-8 shadow-lg magenta"
        />
      )}
    </aside>
  );
};

export default FloatingWidget;
