import React from "react";
import ReactPlayer from "react-player/lazy";
import Vimeo from "@u-wave/react-vimeo";

class VideoPlayer extends React.Component {
  renderVideoPlayer = () => {
    switch (this.props.videoOptions) {
      case "youtube":
        return <ReactPlayer url={this.props.youtubeUrl} />;
      case "vimeo":
        return <Vimeo video={this.props.vimeoId} />;
      default:
        return;
    }
  };

  render() {
    return (
      <div className="video-player-wrapper py-6">
        {this.props.title && <h2 className="mb-1">{this.props.title}</h2>}
        <div className="video-player flex justify-center">{this.renderVideoPlayer()}</div>
      </div>
    );
  }
}

export default VideoPlayer;
