import React from "react";

import Accreditations from "../accreditations";
import Hero from "../hero";
import InfoRows from "../InfoRows";
import CTAColumns from "../cta-columns";
import CTA from "../cta";
import Pricing from "../pricing";
import { TopWave, BottomWave } from "../wave";
import Accordion from "../accordion";
import ContentColumn from "../contentcolumn";
import BlockQuote from "../blockquote";
import Carousel from "../carousel";
import { TwitterTimeline } from "../twitterembed";
import People from "../people";
import Lightbox from "../lightbox";
import Timeline from "../timeline";
import VideoPlayer from "../videoPlayer";
import EmbeddedMap from "../embeddedMap";
import Table from "../table";
import SoundcloudEmbed from "../soundcloudEmbed";
import EmbeddedAudio from "../embeddedAudio";
import GeckoForm from "../geckoForm";
import FloatingWidget from "../floatingWidget";

export default function pageRenderer(c, i, clientConfig) {
  let el = null;
  switch (c._type) {
    case "accordion":
      el = <Accordion key={c._key} {...c} clientConfig={clientConfig} title="" content="" />;
      break;
    case "contentColumn":
      el = <ContentColumn key={c._key} {...c} clientConfig={clientConfig} />;
      break;
    case "blockQuote":
      el = <BlockQuote key={c._key} {...c} />;
      break;
    case "carousel":
      el = <Carousel key={c._key} {...c} clientConfig={clientConfig} />;
      break;
    case "pricing":
      el = <Pricing key={c._key} {...c} />;
      break;
    case "infoRows":
      el = <InfoRows key={c._key} {...c} clientConfig={clientConfig} />;
      break;
    case "hero":
      el = <Hero key={c._key} {...c} clientConfig={clientConfig} />;
      break;
    case "ctaColumns":
      el = <CTAColumns key={c._key} {...c} />;
      break;
    case "ctaPlug":
      el = <CTA key={c._key} {...c} />;
      break;
    case "twitterBody":
      el = <TwitterTimeline key={c._key} {...c} />;
      break;
    case "people":
      el = <People key={c._key} {...c} clientConfig={clientConfig} />;
      break;
    case "lightbox":
      el = <Lightbox key={c._key} {...c} clientConfig={clientConfig} />;
      break;
    case "timeline":
      el = <Timeline key={c._key} {...c} />;
      break;
    case "accreditations":
      el = <Accreditations key={c._key} {...c} clientConfig={clientConfig} />;
      break;
    case "embeddedMap":
      el = <EmbeddedMap key={c._key} {...c} />;
      break;
    case "embeddedAudio":
      el = <EmbeddedAudio key={c._key} {...c} />;
      break;
    case "soundcloudEmbed":
      el = <SoundcloudEmbed key={c._key} {...c} />;
      break;
    case "videoPlayer":
      el = <VideoPlayer key={c._key} {...c} />;
      break;
    case "table":
      el = <Table key={c._key} {...c} />;
      break;
    case "geckoForm":
      el = <GeckoForm key={c._key} {...c} />;
      break;
    case "floatingWidget":
      el = <FloatingWidget key={c._key} {...c} />;
      break;
    case "uiComponentRef":
      switch (c.name) {
        case "topWave":
          el = <TopWave />;
          break;
        case "bottomWave":
          el = <BottomWave />;
          break;
        default:
          break;
      }
      break;
    default:
      el = null;
  }
  return el;
}
