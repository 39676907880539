import React from "react";
import PortableText from "./portableText";
import { getFluidGatsbyImage } from "gatsby-source-sanity";

const maybeImage = (illustration, clientConfig) => {
  let img = null;
  if (
    illustration &&
    illustration.disabled !== true &&
    illustration.image &&
    illustration.image.asset
  ) {
    const fluidProps = getFluidGatsbyImage(
      illustration.image.asset._id,
      { maxWidth: 768 },
      clientConfig.sanity
    );

    img = (
      <img
        src={fluidProps.src}
        alt={illustration.image.alt}
        style={{ maxHeight: "16rem", marginLeft: "auto", marginRight: "auto" }}
      />
    );
  }
  return img;
};

const Row = ({ illustration, clientConfig, title, text }) => {
  const img = maybeImage(illustration, clientConfig);
  const sizeClass = img ? "sm:w-1/2" : "sm:w-full";

  return (
    <>
      <div className={"w-5/6 sm:px-6 " + sizeClass}>
        <h3 className="text-3xl text-gray-800 items-center font-bold leading-none mb-3 ">
          {title}
        </h3>
        <div className="text-gray-700 mb-8">
          <PortableText blocks={text} />
        </div>
      </div>
      {img && <div className={"w-full " + sizeClass}>{img}</div>}
    </>
  );
};

const InfoRows = ({ title, rows, clientConfig }) => {
  return (
    <section className="py-8 mb-8">
      <div className="container max-w-5xl mx-auto m-8">
        {title && <h2 className="my-2 text-4xl font-bold leading-tight text-gray-800">{title}</h2>}
        {
          rows?.filter((row) => !row.disabled).map((row, i) => (
            <div
              key={i}
              className={
                i % 2 !== 0
                  ? "info-row flex flex-col md:flex-row py-6 justify-between items-center"
                  : "info-row flex flex-col md:flex-row-reverse py-6 justify-between items-center"
              
                }
            >
              <Row clientConfig={clientConfig} key={row._key} {...row} />
            </div>
          ))
        }
      </div>
    </section>
  );
};

export default InfoRows;
