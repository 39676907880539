import React from "react";

const EmbeddedMap = ({ scriptURL }) => (
  <iframe
    src={scriptURL}
    title="Embedded Map"
    frameborder="0"
    seamless
    style={{
      width: "100%",
      height: "400px",
      margin: "1rem 0 1.5rem 0",
    }}
  ></iframe>
);

export default EmbeddedMap;
