import React from "react";

const Table = ({ thead, tbody }) => {
  return (
    <div className="overflow-scroll">
      <table className="table-auto border-collapse border border-gray-800 w-full my-3">
        <thead>
          <tr>
            {thead.map((th, index) => (
              <th className="border border-gray-600 p-1" key={index}>
                {th}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tbody.map((row, index) => (
            <tr
              className={
                tbody.indexOf(row) % 2 === 0
                  ? "border border-gray-600 bg-gray-200"
                  : "border border-gray-600"
              }
              key={index}
            >
              {row.data.map((item, index) => (
                <td className="border border-gray-600 p-1" key={index}>
                  {item}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
