import React from "react";
import Slider from "react-slick";
import { getFluidGatsbyImage } from "gatsby-source-sanity";

function NextArrow(props) {
  const { onClick } = props;

  return (
    <svg
      className="slick-arrow-next slick-svg slick-next"
      aria-hidden="true"
      focusable="false"
      data-prefix="far"
      data-icon="slickNext"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 512"
      onClick={onClick}
    >
      <path
        fill="currentColor"
        d="M24.707 38.101L4.908 57.899c-4.686 4.686-4.686 12.284 0 16.971L185.607 256 4.908 437.13c-4.686 4.686-4.686 12.284 0 16.971L24.707 473.9c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L41.678 38.101c-4.687-4.687-12.285-4.687-16.971 0z"
      ></path>
    </svg>
  );
}

function PrevArrow(props) {
  const { onClick } = props;

  return (
    <svg
      className="slick-arrow-prev slick-svg slick-prev"
      aria-hidden="true"
      focusable="false"
      data-prefix="far"
      data-icon="slickPrev"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 512"
      onClick={onClick}
    >
      <path
        fill="currentColor"
        d="M231.293 473.899l19.799-19.799c4.686-4.686 4.686-12.284 0-16.971L70.393 256 251.092 74.87c4.686-4.686 4.686-12.284 0-16.971L231.293 38.1c-4.686-4.686-12.284-4.686-16.971 0L4.908 247.515c-4.686 4.686-4.686 12.284 0 16.971L214.322 473.9c4.687 4.686 12.285 4.686 16.971-.001z"
      ></path>
    </svg>
  );
}

class Carousel extends React.Component {
  render() {
    const { slides } = this.props;
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      lazyLoad: true,
      prevArrow: <PrevArrow />,
      nextArrow: <NextArrow />,
    };

    return (
      <div className="carousel">
        <Slider {...settings}>
          {slides.map((slide, index) => {
            const fluidProps = getFluidGatsbyImage(
              slide.slide.asset._id,
              { maxWidth: 992 },
              this.props.clientConfig.sanity
            );

            return (
              <div key={index}>
                {slide.slide && (
                  <figure>
                    <img src={fluidProps.src} alt={slide.slideImageAlt} />
                    <figcaption className="flex flex-row justify-center py-2">
                      <p className="slideCaption">{slide.slideContent}</p>
                    </figcaption>
                  </figure>
                )}
              </div>
            );
          })}
        </Slider>
      </div>
    );
  }
}

export default Carousel;
