import React from "react";
import { getFluidGatsbyImage } from "gatsby-source-sanity";

const Accreditations = ({ title, accreditation_image, clientConfig }) => {
  return (
    <div className="accreditations-wrapper">
      <div className="accreditations-title">
        <p>{title}</p>
      </div>
      <div className="accreditations-body flex flex-wrap items-center mb-2">
        {accreditation_image.map((image) => {
          const fluidProps = getFluidGatsbyImage(
            image.image.asset._id,
            { maxWidth: 300 },
            clientConfig.sanity
          );

          return (
            <img
              key={image._key}
              className="my-3 mx-auto md:mx-3 w-40 h-auto"
              src={fluidProps.src}
              alt={image.alt}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Accreditations;
