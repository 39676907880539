import React from "react";

export default class GeckoForm extends React.Component {
  render() {
    const { title, scriptURL, formHeightPx } = this.props;
    return (
      <>
        <p className="text-3xl font-bold">{title}</p>
        <iframe
          src={scriptURL}
          title={title}
          seamless
          style={{
            width: "100%",
            height: formHeightPx ? formHeightPx + "px" : "500px",
            margin: "1rem 0 1.5rem 0",
            padding: "1rem 0",
          }}
        ></iframe>
      </>
    );
  }
}
