import React from "react";
import { graphql } from "gatsby";
import clientConfig from "../../client-config";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import pageRenderer from "../../../../../shared_ui/components/renderers/page";

let awsConfig = {};
try {
  awsConfig = require("../aws-exports").default;
} catch (error) {
  // Do nothing. This mess is only to turn a conditional import from an error into a warning
}

export const query = graphql`
  query PageTemplateQuery($id: String!) {
    route: sanityRoute(id: { eq: $id }) {
      slug {
        current
      }
      useSiteTitle
      page {
        title
        metaDescription
        displaySidebar
        ...PageInfo
      }
      ...PageRouteData
    }
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      ...SiteSettings
    }
  }
`;

const Page = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  const page = data.page || data.route.page;

  const content = (page._rawContent || [])
    .filter((c) => !c.disabled)
    .map((c, i) => {
      let el = pageRenderer(c, i, clientConfig);
      return el;
    });

  const menuItems = page.navMenu && (page.navMenu.items || []);
  const pageTitle = page.title;
  const metaDescription = page.metaDescription || data.site.metaDescription;

  const socials = site.socials || {};

  const enableGtag = (site.analytics && site.analytics.enableGtag) || "";
  const gtagId = (site.analytics && site.analytics.gtagId) || "";

  const displaySidebar = page.displaySidebar;

  return (
    <Layout
      navMenuItems={menuItems}
      socials={socials}
      displaySidebar={displaySidebar}
      awsConfig={awsConfig}
    >
      {enableGtag && (
        <noscript>
          <iframe
            src={`https://www.googletagmanager.com/ns.html?id=${gtagId}`}
            height="0"
            width="0"
            style={{ display: "none", visibility: "hidden" }}
            title="Google Tag Manager"
          ></iframe>
        </noscript>
      )}
      <SEO
        title={pageTitle}
        description={metaDescription}
        keywords={site.keywords} // TODO: This will likely need fixing, similar to how description was.
        openGraphImage={props.data.site.openGraph?.image}
        bodyAttr={{
          class: "leading-normal tracking-normal",
        }}
      />
      <div>
        <h1 className="text-3xl md:text-4xl mb-2">{pageTitle}</h1>
        <>{content}</>
      </div>
    </Layout>
  );
};

export default Page;
