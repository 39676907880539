import React from "react";
import FocusTrap from "focus-trap-react";
import { getFluidGatsbyImage } from "gatsby-source-sanity";

class Lightbox extends React.Component {
  state = {
    imageIndex: 0,
    isLightboxOpen: false,
  };

  handleImageClick = (e, index) => {
    e.preventDefault();
    this.setState({ isLightboxOpen: !this.state.isLightboxOpen, imageIndex: index });
  };

  closeLightboxDisplay = () => {
    this.setState({ isLightboxOpen: !this.state.isLightboxOpen });
  };

  prevImage = () => {
    this.setState({ imageIndex: this.state.imageIndex - 1 });
  };

  nextImage = () => {
    this.setState({ imageIndex: this.state.imageIndex + 1 });
  };

  handleKeyDown = (e) => {
    const ESC_KEY = 27;

    switch (e.keyCode) {
      case ESC_KEY:
        this.closeLightboxDisplay();
        break;

      default:
        break;
    }
  };

  render() {
    const { title, clientConfig, lightbox_content } = this.props;

    return (
      <div className="lightbox-wrapper">
        {title && <h2>{title}</h2>}
        <div className="lightbox-gallery">
          {lightbox_content?.map((item, index) => {
            const fluidProps = getFluidGatsbyImage(
              item.image.asset._id,
              { maxWidth: 992 },
              clientConfig.sanity
            );

            return (
              <button
                className="lightbox-gallery-item"
                key={index}
                onClick={(e) => this.handleImageClick(e, index)}
              >
                <img src={fluidProps.src} alt={item.caption} />
              </button>
            );
          })}
        </div>
        {this.state.isLightboxOpen && (
          <FocusTrap active={this.state.isLightboxOpen}>
            <div
              className="lightbox-modal"
              role="button"
              tabIndex={0}
              onKeyDown={(e) => this.handleKeyDown(e)}
            >
              <div className="lightbox-modal-content" aria-modal="true" role="dialog">
                <button
                  className="modal-control-close"
                  onClick={this.closeLightboxDisplay}
                  aria-label="Close image viewer"
                >
                  X
                </button>
                <figure>
                  <figcaption>
                    <p>{lightbox_content[this.state.imageIndex].caption}</p>
                  </figcaption>
                  <img
                    src={lightbox_content[this.state.imageIndex].image.asset.url}
                    alt={lightbox_content[this.state.imageIndex].caption}
                  />
                </figure>
                <button
                  className={
                    this.state.imageIndex === 0 ? "modal-control-prev-hidden" : "modal-control-prev"
                  }
                  onClick={this.prevImage}
                >
                  <svg
                    className="prev-arrow"
                    focusable="true"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 256 512"
                    alt="Previous image"
                  >
                    <path
                      fill="currentColor"
                      d="M231.293 473.899l19.799-19.799c4.686-4.686 4.686-12.284 0-16.971L70.393 256 251.092 74.87c4.686-4.686 4.686-12.284 0-16.971L231.293 38.1c-4.686-4.686-12.284-4.686-16.971 0L4.908 247.515c-4.686 4.686-4.686 12.284 0 16.971L214.322 473.9c4.687 4.686 12.285 4.686 16.971-.001z"
                    ></path>
                  </svg>
                </button>
                <button
                  className={
                    this.state.imageIndex === lightbox_content.length - 1
                      ? "modal-control-next-hidden"
                      : "modal-control-next"
                  }
                  onClick={this.nextImage}
                >
                  <svg
                    className="next-arrow"
                    focusable="true"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 256 512"
                    alt="Next image"
                  >
                    <path
                      fill="currentColor"
                      d="M24.707 38.101L4.908 57.899c-4.686 4.686-4.686 12.284 0 16.971L185.607 256 4.908 437.13c-4.686 4.686-4.686 12.284 0 16.971L24.707 473.9c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L41.678 38.101c-4.687-4.687-12.285-4.687-16.971 0z"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>
          </FocusTrap>
        )}
      </div>
    );
  }
}

export default Lightbox;
