import React from "react";
import PropTypes from "prop-types";

const BlockQuote = ({ quote, author, jobTitle, image }) => {
  let backgroundImageUrl;

  if (image != null) {
    backgroundImageUrl = image && image.asset && image.asset.url;
  } else {
    backgroundImageUrl = false;
  }

  return (
    <div className="blockQuote">
      <div className="container mx-auto flex flex-col md:flex-row">
        {backgroundImageUrl ? (
          <div className="w-full md:w-1/3">
            <div
              className="quoteImage mb-4 md:mb-0"
              style={{ backgroundImage: `url("${backgroundImageUrl}")` }}
            ></div>
          </div>
        ) : (
          ""
        )}
        <div className={backgroundImageUrl ? "w-full md:w-2/3" : ""}>
          <p className="quote">{quote}</p>
          <span style={{ fontWeight: "700" }}>{author}</span>
          <span>{jobTitle}</span>
        </div>
      </div>
    </div>
  );
};

BlockQuote.propTypes = {
  quote: PropTypes.string,
  author: PropTypes.string,
  jobTitle: PropTypes.string,
  image: PropTypes.object,
};

export default BlockQuote;
