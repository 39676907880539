import React from "react";
import { Link } from "gatsby";
import "react-vertical-timeline-component/style.min.css";
import { internalLink } from "../lib/helpers";

export default ({ title, subtitle, content, link }) => {
  let href;
  let internal;
  let linkPresent = false;
  if (link != null) {
    href = link.route || link.link || "#";
    internal = internalLink(link);
    if (link.landingPageRoute && link.landingPageRoute.slug && link.landingPageRoute.slug.current) {
      internal = true;
      href = link.landingPageRoute.slug.current;
    }
    linkPresent = true;
  }

  return (
    <>
      <h3 className="vertical-timeline-element-title">{title}</h3>
      <h4 className="vertical-timeline-element-subtitle text-base">{subtitle}</h4>
      <p>{content}</p>
      {linkPresent && (
        <p>
          {internal ? (
            <Link to={"/" + href}>Read more</Link>
          ) : (
            <a href={href} target="_blank" rel="noopener noreferrer">
              Read more
            </a>
          )}
        </p>
      )}
    </>
  );
};
