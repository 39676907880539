import React from "react";
import PortableText from "./portableText";
import { getFluidGatsbyImage } from "gatsby-source-sanity";

const People = ({ peopleTitle, peopleList, clientConfig }) => {
  return (
    <div>
      <h2>{peopleTitle}</h2>
      {peopleList.map((person) => {
        let fluidProps;
        if (person.personImage) {
          fluidProps = getFluidGatsbyImage(
            person.personImage.asset._id,
            { maxWidth: 250 },
            clientConfig.sanity
          );
        }

        return (
          <div key={person._key} className="person flex flex-col md:flex-row">
            {person.personImage && <img src={fluidProps.src} alt={person.personName} />}
            <div>
              <h3>{!person.personName ? null : `${person.personName}`}</h3>
              <span>{!person.personJob ? null : `${person.personJob}`}</span>
              {!person.personBio ? null : (
                <PortableText blocks={person.personBio} clientConfig={clientConfig} />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default People;
