import React from "react";
import { Link } from "gatsby";
import { internalLink } from "../lib/helpers";

const CTALink = (props) => {
  let link = props.route || props.link || "#";
  let internal = internalLink(link);
  if (
    props.landingPageRoute &&
    props.landingPageRoute.slug &&
    props.landingPageRoute.slug.current
  ) {
    internal = true;
    link = props.landingPageRoute.slug.current;
  }
  // Four different options: internal/external, and button/link styles.
  if (internal) {
    return (
      <Link className={props.kind === "button" ? props.buttonActionClass : "mr-3"} to={link}>
        {props.title}
      </Link>
    );
  } else {
    return (
      <a
        id="navAction"
        href={link}
        className={props.kind === "button" ? props.buttonActionClass : "mr-3"}
        target="_blank"
        rel="noopener noreferrer"
      >
        {props.title}
      </a>
    );
  }
};

export default CTALink;
